import {
  getStorageItem,
  setStorageItem,
  removeStorageItem,
} from "modules/storage";

const SESSION_STORAGE_KEY = "session";

export const emptySession = {
  email: "",
  token: null,
};

export function getInitialSession() {
  const session = getSession();

  return session || emptySession;
}

export function getSession() {
  let session = getStorageItem(SESSION_STORAGE_KEY);

  // check for undefined
  if (session) {
    session = JSON.parse(session);
  }

  return session;
}

export function setSession({ email, token }) {
  setStorageItem(SESSION_STORAGE_KEY, JSON.stringify({ email, token }));
}

export function removeSession() {
  removeStorageItem(SESSION_STORAGE_KEY);
}

export function isSessionValid({ email, token }) {
  return email !== "" && token !== null;
}
