const theme = {
  colors: {
    black: "#000000",
    semiBlack: "#333333",
    darkGrey: "#555555",
    grey: "#777777",
    lightGrey: "#DDDDDD",
    veryLightGrey: "#EEEEEE",
    white: "#FFFFFF",
  },
  breakpoints: {
    md: "768px",
    lg: "1024px",
    xl: "1440px",
  },
  padding: {
    unit: 20,
  },
};

export default theme;
