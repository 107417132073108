import axios from "axios";

import { prefixApiDomain } from "../utils";

export function checkSession({ email, token }) {
  return axios.post(prefixApiDomain("/api/session"), {
    email,
    token,
  });
}
