import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import { LOGIN_ROUTE } from "@routes";

import { checkSession as checkSessionService } from "apiClient/auth/session";

import {
  emptySession,
  getInitialSession,
  setSession,
  removeSession,
  isSessionValid,
} from "modules/auth/session";

export const AuthContext = React.createContext();

class AuthContextProvider extends React.Component {
  state = {
    checkingSession: true,
    session: getInitialSession(),
    user: null,
  };

  async componentDidMount() {
    const { session } = this.state;

    if (isSessionValid(session)) {
      this.checkSession(session);
    } else {
      this.setState({ checkingSession: false });
      this.removeSession();
    }
  }

  checkSession = async (session) => {
    try {
      const result = await checkSessionService(session);

      const sessionData = {
        email: session.email,
        token: result.data.token,
      };

      const user = result.data.user;

      this.setUser(user);
      this.setSession(sessionData);
    } catch (err) {
      this.removeSession();
    }
  };

  setSession = (session) => {
    this.setState({
      checkingSession: false,
      session,
    });
    setSession(session);
  };

  removeSession = () => {
    this.setState({
      checkingSession: false,
      session: emptySession,
    });
    removeSession();
  };

  setUser = (user) => {
    this.setState({ user });
  };

  removeUser = () => {
    this.setState({ user: null });
  };

  logout = () => {
    this.removeSession();
    this.removeUser();

    navigate(LOGIN_ROUTE);
  };

  render() {
    const { checkingSession, session, user } = this.state;
    const isAuthenticated = !checkingSession && isSessionValid(session);

    return (
      <AuthContext.Provider
        value={{
          checkingSession,
          isAuthenticated,
          session,
          setSession: this.setSession,
          removeSession: this.removeSession,
          user,
          setUser: this.setUser,
          removeUser: this.removeUser,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default AuthContextProvider;
