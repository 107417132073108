const storage =
  typeof window !== "undefined"
    ? window.localStorage
    : {
        getItem: () => {},
        setStorageItem: () => {},
        removeStorageItem: () => {},
        clearStorageItems: () => {},
      };

export function getStorageItem(key) {
  return storage.getItem(key);
}

export function setStorageItem(key, value) {
  return storage.setItem(key, value);
}

export function removeStorageItem(key) {
  return storage.removeItem(key);
}

export function clearStorageItems() {
  return storage.clear();
}
