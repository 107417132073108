module.exports.NOT_FOUND_ROUTE = "/404";
module.exports.CART_ROUTE = "/carrito";
module.exports.CATEGORIES_ROUTE = "/categorias";
module.exports.CHANGE_PASSWORD_ROUTE = "/cambiar-contrasena";
module.exports.CHECKOUT_ROUTE = "/hacer-pedido";
module.exports.LEGAL_NOTE_ROUTE = "/nota-legal";
module.exports.LOGIN_ROUTE = "/iniciar-sesion";
module.exports.PROFILE_ROUTE = "/perfil";
module.exports.REGISTER_ROUTE = "/registro";
module.exports.RESET_PASSWORD_ROUTE = "/restablecer-contrasena";

module.exports.pagesMapping = {
  "/404": module.exports.NOT_FOUND_ROUTE,
  "/cart": module.exports.CART_ROUTE,
  "/categories": module.exports.CATEGORIES_ROUTE,
  "/change-password": module.exports.CHANGE_PASSWORD_ROUTE,
  "/checkout": module.exports.CHECKOUT_ROUTE,
  "/legal-note": module.exports.LEGAL_NOTE_ROUTE,
  "/login": module.exports.LOGIN_ROUTE,
  "/profile": module.exports.PROFILE_ROUTE,
  "/register": module.exports.REGISTER_ROUTE,
  "/reset-password": module.exports.RESET_PASSWORD_ROUTE,
};
