// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-note-js": () => import("./../../../src/pages/legal-note.js" /* webpackChunkName: "component---src-pages-legal-note-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-subcategory-js": () => import("./../../../src/templates/subcategory.js" /* webpackChunkName: "component---src-templates-subcategory-js" */)
}

