/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import PropTypes from "prop-types";

import { ThemeProvider } from "@emotion/react";
import theme from "@theme";

import { ShoppingCartProvider } from "components/ShoppingCart";
import AuthContextProvider from "contexts/AuthContext";

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <ShoppingCartProvider>{element}</ShoppingCartProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
};

wrapRootElement.propTypes = {
  element: PropTypes.object,
};
