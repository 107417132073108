import { customColorOption } from "@globals";
import { getStorageItem, setStorageItem } from "modules/storage";

export const addToCart = (items, item) => {
  return [...items, { data: item }];
};

export const removeFromCart = (items, index) => {
  return items.filter((_, j) => {
    return j !== index;
  });
};

export const getTotalPrice = (items) =>
  items
    .reduce(
      (prev, current) => prev + parseFloat(current.data.price.toFixed(2)),
      0
    )
    .toFixed(2);

export const getUniqueCartItems = (items) => {
  return items.reduce((acc, currentCartItem) => {
    const cartItemIndex = acc.findIndex((cartItem) => {
      return (
        cartItem.data.title === currentCartItem.data.title &&
        cartItem.data.size === currentCartItem.data.size
      );
    });

    if (cartItemIndex === -1) {
      return [...acc, currentCartItem];
    }

    const cartItem = acc[cartItemIndex];

    const isNewCustomColorRequired =
      cartItem.data.color !== currentCartItem.data.color ||
      cartItem.data.color === customColorOption;

    const customColorsForCartItem = new Array(cartItem.data.quantity).fill(
      cartItem.data.color
    );
    const customColorsForCurrentCartItem = new Array(
      currentCartItem.data.quantity
    ).fill(currentCartItem.data.color);

    acc[cartItemIndex] = {
      ...cartItem,
      data: {
        ...cartItem.data,
        // Just add quantity
        quantity: cartItem.data.quantity + currentCartItem.data.quantity,
        // Just add price
        price: cartItem.data.price + currentCartItem.data.price,
        // If the color is the same, keep it, otherwise use the custom color and add the custom colors
        color: isNewCustomColorRequired
          ? customColorOption
          : cartItem.data.color,
        customColors: isNewCustomColorRequired
          ? [
              ...(cartItem.data.color === customColorOption
                ? cartItem.data.customColors
                : customColorsForCartItem),
              ...(currentCartItem.data.color === customColorOption
                ? currentCartItem.data.customColors
                : customColorsForCurrentCartItem),
            ]
          : cartItem.data.customColors,
      },
    };

    return acc;
  }, []);
};

export const getTotalNumberItems = (items) => {
  return getUniqueCartItems(items).length;
};

export const getTotalNumberClothes = (items) => {
  return getUniqueCartItems(items).reduce(
    (prev, current) => prev + parseInt(current.data.quantity, 10),
    0
  );
};

const CART_STORAGE_KEY = "cart";
export function getStorageCart() {
  const storageCart = getStorageItem(CART_STORAGE_KEY);

  if (storageCart === null) {
    return [];
  }

  try {
    return JSON.parse(storageCart);
  } catch (error) {
    return [];
  }
}

export function setStorageCart(cart) {
  setStorageItem(CART_STORAGE_KEY, JSON.stringify(cart));
}
