import React from "react";
import PropTypes from "prop-types";

import {
  addToCart,
  removeFromCart,
  getStorageCart,
  setStorageCart,
} from "modules/cart";

export class ShoppingCartProvider extends React.PureComponent {
  state = {
    cart: getStorageCart(),
  };

  addToCart = (item) => {
    this.setState((state) => {
      const updatedCart = addToCart(state.cart, item);
      setStorageCart(updatedCart);
      return { cart: updatedCart };
    });
  };

  removeFromCart = (index) => {
    this.setState((state) => {
      const updatedCart = removeFromCart(state.cart, index);
      setStorageCart(updatedCart);
      return { cart: updatedCart };
    });
  };

  resetCart = () => {
    setStorageCart([]);
    this.setState({ cart: [] });
  };

  render() {
    return (
      <ShoppingCartContext.Provider
        value={{
          cart: this.state.cart,
          addToCart: this.addToCart,
          removeFromCart: this.removeFromCart,
          resetCart: this.resetCart,
        }}
      >
        {this.props.children}
      </ShoppingCartContext.Provider>
    );
  }
}

ShoppingCartProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export const ShoppingCartContext = React.createContext({
  cart: [],
  addToCart: () =>
    console.error("you didn't set a provider for the ShoppingCartContext"), // eslint-disable-line
  removeFromCart: () =>
    console.error("you didn't set a provider for the ShoppingCartContext"), // eslint-disable-line
});

export const withShoppingCart = (Component) => {
  const withShoppingCartHOC = (props) => (
    <ShoppingCartContext.Consumer>
      {(cartProps) => <Component {...cartProps} {...props} />}
    </ShoppingCartContext.Consumer>
  );

  return withShoppingCartHOC;
};
